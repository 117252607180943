<template>
  <div class="app-mobile-config">
    <h1>两步将图标添加到桌面</h1>
    <div class="step-1">
      <h3>下载文件，然后点击「允许」</h3>
      <img src="https://static1.kaixinyf.cn/img/lza63abd5e02abe4722021327.png" alt="" />
      <a :href="stepOneLink"><button class="download" @click="handleStepOne">下载</button></a>
    </div>
    <div class="step-2">
      <h3>进入描述文件页面，选择描述文件点击“安装”</h3>
      <img src="https://static1.kaixinyf.cn/img/lza63abd5998c1c9774369230.png" alt="" />
      <a href="https://mobile-config.xiaozujian.com/embedded5.mobileprovision" rel="noopener noreferrer"
        ><button class="download" @click="handleStepTwo">进入描述文件页面</button></a
      >
    </div>
    <div class="side-bar">
      <div class="side-bar-top" :class="{ active1: step1Active, active2: step2Active }">1</div>
      <div class="side-bar-bottom" :class="{ active2: step2Active }">2</div>
    </div>
  </div>
</template>
<script>
  export default {
    components: {},
    props: {},
    name: 'app-mobile-config',
    data() {
      return {
        step1Active: false,
        step2Active: false,
        stepOneLink: '',
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      const downloadUrl = this.$route.query.downloadUrl || '';

      this.stepOneLink = downloadUrl;
    },
    methods: {
      handleStepOne() {
        this.step1Active = true;
      },
      handleStepTwo() {
        this.step2Active = true;
      },
    },
  };
</script>
<style scoped lang="less">
  .app-mobile-config {
    position: relative;
    h1 {
      margin: 0.4rem auto;
      font-size: 0.38rem;
      height: 0.52rem;
      font-weight: 600;
      color: #000000;
      line-height: 0.52rem;
      text-align: center;
    }
    .step-1 {
      margin: 0 auto;
      width: 5.34rem;
      h3 {
        width: 100%;
        font-size: 0.32rem;
        font-weight: 500;
        color: #4d4547;
      }
      img {
        margin-top: 0.3rem;
        width: 5.34rem;
        height: 2.12rem;
        background: #f3f2f8;
        border-radius: 0.3rem;
      }
      button.download {
        display: block;
        margin: 0.6rem auto 0.82rem;
        width: 3.12rem;
        height: 0.86rem;
        background: #f76693;
        border-radius: 0.54rem;
        text-align: center;
        border: none;
        font-size: 0.32rem;
        font-weight: 600;
        color: #ffffff;
      }
    }
    .step-2 {
      margin: 0 auto;
      width: 5.34rem;
      h3 {
        width: 100%;
        font-size: 0.32rem;
        font-weight: 500;
        color: #4d4547;
      }
      img {
        margin-top: 0.3rem;
        width: 5.34rem;
        height: 4.8rem;
        background: #f3f2f8;
        border-radius: 0.3rem;
      }
      button.download {
        display: block;
        margin: 0.6rem auto 0.82rem;
        width: 3.12rem;
        height: 0.86rem;
        background: #f76693;
        border-radius: 0.54rem;
        text-align: center;
        border: none;
        font-size: 0.32rem;
        font-weight: 600;
        color: #ffffff;
      }
    }
    .side-bar {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: absolute;
      top: 0.88rem;
      left: 0.4rem;
      width: 0.48rem;
      height: 5.64rem;
      &-top {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0.48rem;
        height: 0.48rem;
        border-radius: 50%;
        background-color: #d6d2d4;
        color: #ffffff;
        font-weight: 600;
        font-size: 0.32rem;
        &::after {
          content: '';
          position: absolute;
          bottom: -2.375rem;
          left: 50%;
          transform: translateX(-50%);
          height: 2.375rem;
          width: 0.04rem;
          background: #d6d2d4;
        }
      }
      &-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0.48rem;
        height: 0.48rem;
        border-radius: 50%;
        background-color: #d6d2d4;
        color: #ffffff;
        font-weight: 600;
        font-size: 0.32rem;
        &::before {
          content: '';
          position: absolute;
          top: 2.855rem;
          left: 50%;
          transform: translateX(-50%);
          height: 2.375rem;
          width: 0.04rem;
          background: #d6d2d4;
        }
      }
    }
    .active1 {
      background: #f76693;
    }
    .active2 {
      background: #f76693;

      &::after {
        background: #f76693;
      }

      &::before {
        background: #f76693;
      }
    }
  }
</style>
